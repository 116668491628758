import React, { useState, Suspense } from 'react';
import Skeleton from './loading_skeleton';

const OrderReviewContainer = React.lazy(() => {
  return import("shipping/OrderReviewContainer");
});

const OrderReviewComponent = (props) => {
  function resultMessage(message) {
    console.log(message);
  }

  const SkeletonLayout = () => {
    return (
      <div
        className="tw-mx-2.5 xs:tw-mx-auto"
        style={{
          minHeight: 'calc(100vh - 397px)',
          textAlign: 'center',
          paddingTop: '40px',
          margin: '0 auto',
          display: 'grid',
          gridTemplateColumns: '1fr 320px',
          gridTemplateAreas: `'main sidebar'`,
          columnGap: '24px',
          maxWidth: 'calc(528px + 320px + 24px)',
        }}
      >
        <Skeleton height="55rem" width="100%" className="" style={{ gridArea: 'main' }} />
        <Skeleton height="31rem" width="100%" style={{ gridArea: 'sidebar' }} />
      </div>
    );
  };

  const handleSavePaymentInfo = async (paymentMethodType, paymentMethod) => {
    const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    try {
      const response = await fetch("/checkout/save_payment_info", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": token,
        },
        body: JSON.stringify({
          paymentMethodType: paymentMethodType,
          paymentMethod: paymentMethod,
        }),
      });
      if (response.ok) {
        return true
      } else {
        console.error(await response.json());
        return false
      }
    } catch (error) {
      console.error(error);
      resultMessage(`Could not save payment information...${error}`);
      return false
    }
  }

  const [orderReviewProps, setOrderReviewProps] = useState({});
  const [showingChangedOrderItems, setShowingChangedOrderItems] = useState(false);

  const fetchOrderReviewProps = async () => {
    try {
      const response = await fetch("/checkout/order_review_props", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      setOrderReviewProps(data);
    } catch (error) {
      console.error('Error fetching order review props:', error);
    }
  };

  const updateShippingOption = async (shipping) => {
    const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    try {
      const response = await fetch("/checkout/review/shipping_option", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": token,
        },
        body: JSON.stringify({
          shippingOption: shipping.shippingOption,
          shippingAccount: shipping.shippingAccount
        }),
      });

      if (response.ok) {
        return response.json()
      } else {
        console.error(await response.json());
        return false
      }
    } catch (error) {
      resultMessage(`Could not update shipping information...${error}`);
      return false
    }
  }

  const checkForChangedOrderItems = async (mfeOrderItems) => {
    try {
      const response = await fetch("/checkout/condensed_cart_items", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const condensedCartItems = await response.json();
      const itemsChanged = JSON.stringify(condensedCartItems) !== JSON.stringify(mfeOrderItems);

      if (itemsChanged) {
        setShowingChangedOrderItems(true);
        await fetchOrderReviewProps();
      } else {
        setShowingChangedOrderItems(false);
      }

      return itemsChanged;
    } catch (error) {
      console.error('Error fetching condensed cart items:', error);
      return false;
    }
  };

  const handlePlaceOrder = async (payment, shippingMethodId, shippingAddressId, orderId, shippingAccountId, promoCode, shippingCost, shippingMethodServiceName, poNumber) => {
    const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    try {
      const response = await fetch("/checkout/place_order", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": token,
        },
        body: JSON.stringify({
          payment: payment,
          shippingMethodId: shippingMethodId,
          shippingAddressId: shippingAddressId,
          orderId: orderId,
          promoCode: promoCode,
          shippingAccountId: shippingAccountId,
          shippingCost: shippingCost,
          shippingMethodServiceName: shippingMethodServiceName,
          poNumber: poNumber,
        }),
      });
      const data = await response.json();

      if (data.redirectUrl) {
        window.location.href = data.redirectUrl;
      }
      return data;
    } catch (error) {
      console.error(error);
      resultMessage(`Could not place order...${error}`);
      return false;
    }
  };

  return (
    <div style={{ minHeight: 'calc(100vh - 397px)', textAlign: 'center', paddingTop: '40px' }}>
      <Suspense fallback={<SkeletonLayout />}>
        <OrderReviewContainer
          handlePlaceOrder={handlePlaceOrder}
          onUpdatePaymentMethod={handleSavePaymentInfo}
          checkForChangedOrderItems={checkForChangedOrderItems}
          showingChangedOrderItems={showingChangedOrderItems}
          updateShippingOption={updateShippingOption}
          {...props}
          {...orderReviewProps}
        />
      </Suspense>
    </div>
  );
};

export default OrderReviewComponent;
