import React, { Suspense } from 'react';

const AdminManageShippingAccountsContainer = React.lazy(async () => {
  return await import("shipping/AdminManageShippingAccountsContainer");
});

const AdminManageShippingAccountsComponent = (props) => {
  const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
  const getOrdersForAccountCallback = async (accountId) => {
    const response = await fetch(`/admin/shipping_accounts/${accountId}/orders`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token,
      }
    });
    if (response.ok) {
      return await response.json()
    } else {
      throw new Error("Unable to retrieve orders");
    }
  }

  const refundOrderCallback = async (orderId) => {
    const response = await fetch(`/admin/orders/${orderId}/refund`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token,
      }
    });
    if (response.ok) {
      return true
    } else {
      throw new Error("An error occured while refunding this order");
    }
  }

  const approveOrdersForAccountCallback = async (accountId) => {
    const response = await fetch(`/admin/shipping_accounts/approve/${accountId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token,
      }
    });
    if (response.ok) {
      return true
    } else {
      throw new Error("An error occured while approving orders for this account");
    }
  }
  const switchShippingAccountForOrderCallback = async (accountId, orderId) => {
    const response = await fetch(`/admin/orders/switch_shipping_account/${orderId}?shipping_account_id=${accountId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token,
      }
    });
    if (response.ok) {
      return await response.json();
    } else {
      const error = await response.json();
      throw new Error(error.error_message);
    }
  }


  const markOrdersAsShippingUnpaidCallback = async (accountId) => {
    const response = await fetch(`/admin/orders/mark_orders_as_shipping_unpaid/${accountId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token,
      }
    });
    if (response.ok) {
      return true
    } else {
      throw new Error("An error occured while approving orders for this account");
    }
  }

  return (
    <div>
      <Suspense fallback={'Loading...'}>
        <AdminManageShippingAccountsContainer
          getOrdersForAccountCallback={getOrdersForAccountCallback}
          refundOrderCallback={refundOrderCallback}
          approveOrdersForAccountCallback={approveOrdersForAccountCallback}
          switchShippingAccountForOrderCallback={switchShippingAccountForOrderCallback}
          markOrdersAsShippingUnpaidCallback={markOrdersAsShippingUnpaidCallback}
        />
      </Suspense>
    </div>
  )
}

export default AdminManageShippingAccountsComponent