import {
  init,
  makeFetchTransport,
  moduleMetadataIntegration,
  makeMultiplexedTransport,
} from '@sentry/browser';

import { browserTracingIntegration, replayIntegration } from '@sentry/react'
import { Domain } from 'domain';

const EXTRA_KEY = 'ROUTE_TO';

const transport = makeMultiplexedTransport(makeFetchTransport, args => {
  const event = args.getEvent();
  if (
    event &&
    event.extra &&
    EXTRA_KEY in event.extra &&
    Array.isArray(event.extra[EXTRA_KEY])
  ) {
    return event.extra[EXTRA_KEY];
  }
  return [];
});

export default function initializeSentry() {
  // get domain
  const domain = window.location.hostname;
  const environment = domain == 'www.wirecare.com' ? 'production' : 'development';
  init({
    dsn: 'https://9b0c607de6204ec7a9038f3194196ee2@o4505195236098048.ingest.us.sentry.io/4505195260280832',
    ignoreErrors: [
      'CustomEvent: Event `CustomEvent` (type=unhandledrejection) captured as promise rejection',
      /UnhandledRejection: Non-Error promise rejection captured.*/,
      'TypeError: Cannot read properties of undefined (reading \'value\')',
      'undefined is not an object (evaluating \'__gCrWeb.instantSearch.setIOSParameters\')',
      'Error: JSONP request failed.',
      'ReferenceError: _ltk is not defined',
      'ReferenceError: Can\'t find variable: _ltk',
      'Error: Multiple Sentry Session Replay instances are not supported'
    ],
    integrations: [
      moduleMetadataIntegration(),
      browserTracingIntegration(),
      replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Session Replay
    replaysSessionSampleRate: 0.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    transport,
    environment: environment,
    beforeSend: event => {
      if (event?.exception?.values?.[0]?.stacktrace?.frames) {
        const frames = event.exception.values[0].stacktrace.frames;
        // Find the last frame with module metadata containing a DSN
        const routeTo = frames
          .filter(frame => frame.module_metadata && frame.module_metadata.dsn)
          .map(v => v.module_metadata)
          .slice(-1); // using top frame only - you may want to customize this according to your needs

        if (routeTo.length) {
          event.extra = {
            ...event.extra,
            [EXTRA_KEY]: routeTo,
          };
        }
      }
      return event;
    }
  });
}