export default function logo() {
  return (
    <svg width="100%" height="100%" viewBox="0 0 186 160" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2 }}>
      <path id="_bkg_" fill="#8CB8D1" d="M30.063,149.25l46.33,9.891l31.299,-37.312l46.329,9.891l31.299,-37.312l-15.032,-47.204l-31.298,37.312l-46.33,-9.891l-31.299,37.312l-46.329,-9.891l15.031,47.204Z" />
      <path id="_x36_" fill="#D2E1EF" d="M155.257,0l-31.298,37.312l46.329,9.892l-15.031,-47.204Z" />
      <path id="_x35_" fill="#8CB8D1" d="M170.288,47.204l-31.298,37.312l15.031,47.204l31.299,-37.312l-15.032,-47.204Z" />
      <path id="_x34_" fill="#8CB8D1" d="M92.66,74.625l15.032,47.204l46.329,9.891l-15.031,-47.204l-46.33,-9.891Z" />
      <path id="_x33_" fill="#8CB8D1" d="M61.361,111.937l15.032,47.204l31.299,-37.312l-15.032,-47.204l-31.299,37.312Z" />
      <path id="_x32_" fill="#8CB8D1" d="M15.032,102.046l15.031,47.204l46.33,9.891l-15.032,-47.204l-46.329,-9.891Z" />
      <path id="_x31_" fill="#D92E22" d="M0,54.842l15.032,47.204l31.298,-37.313l-46.33,-9.891Z" />
    </svg>
  )
}