import React, { Suspense } from 'react'

const CheckoutContainer = React.lazy(async () => {
  const checkoutEntry = await import("checkout/CheckoutContainer");
  return checkoutEntry;
});

const CheckoutComponent = (props) => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <CheckoutContainer {...props} />
    </Suspense>
  )
}

export default CheckoutComponent